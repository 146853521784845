import { CreateOrganizationPayload } from "@/services/admin.service";
import { Organization } from "@/services/organization.service";
import { onMounted, reactive, toRefs } from "@vue/composition-api";

interface UseOrganizationEditorState {
  loading: boolean;
  organization: CreateOrganizationPayload;
}
interface UseOrganizationEditorParams {
  currentOrganization?: Organization;
}
const useOrganizationEditor = ({
  currentOrganization
}: UseOrganizationEditorParams) => {
  const state = reactive<UseOrganizationEditorState>({
    loading: true,
    organization: {}
  });

  onMounted(() => {
    if (currentOrganization) {
      state.organization = currentOrganization;
    }
  });
  return { ...toRefs(state) };
};

export default useOrganizationEditor;
