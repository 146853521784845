
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import ValidateError from "@/components/common/ValidateError.vue";
import useOrganizationEditor from "@/composables/use-organization-editor.ts";
import { ref } from "@vue/composition-api";
// eslint-disable-next-line no-unused-vars
import { Organization } from "@/services/organization.service.ts";
// eslint-disable-next-line no-unused-vars
import attendTime from "@/mock/attendTime.ts";
@Component({ components: { ValidateError } })
export default class OrganizationEditor extends Vue {
  @Prop()
  private currentOrganization?: Organization;

  @Prop()
  private validation?: any;
  @Prop({ type: Boolean, default: false })
  private isCreate?: boolean;

  @Emit("submit-organization")
  onSubmitOrganization(): void {}

  attendDropdown = attendTime;

  setup(props: { currentOrganization: Organization }) {
    const labelCol = ref({ span: 2 });
    const wrapperCol = ref({ span: 8 });
    const { organization: organize } = useOrganizationEditor({
      currentOrganization: props.currentOrganization
    });
    const organizationEngine = ref([
      { label: "Twilio", value: "twilio" },
      { label: "Tokbox", value: "tokbox" },
      { label: "Zoom", value: "zoom" },
      { label: "Whereby", value: "whereby" }
    ]);
    return { labelCol, wrapperCol, organize, organizationEngine };
  }
}
